import {
  Avatar,
  Grid,
  LocalPhoneIcon,
  QuestionAnswerIcon,
  styledComponent,
  Typography,
  Box,
  EventAvailableIcon,
  useTheme,
  useMediaQuery,
  Rating
} from '@hermes/web-components'
import { PropsWithChildren, useContext } from 'react'
import { ProfileContext } from '../../../providers/AppData/Profile'
import useTranslateMessage from '../../../hooks/useTranslateMessage'
import { RequestTypes } from '../../../constants/profile'
import { RevealLink } from '../../RevealLink'
import { ContactDescriptionProps, Event, SpecialistPracticeBookingCardProps } from './types'
import { AppDataContext } from '../../../providers/AppData'

const Root = styledComponent(Grid)(({ theme }) => ({
  padding: '16px 0',
  width: '100%',
  '&:not(:last-child)': {
    borderBottom: `1px solid ${theme.palette.divider}`
  },
  [theme.breakpoints.down('sm')]: {
    flexDirection: 'column'
  }
}))

export const RevealsContainer = styledComponent(Box)(({ theme }) => ({
  display: 'flex',
  gap: '8px',
  width: '100%',
  marginTop: '16px',
  flexWrap: 'wrap',
  [theme.breakpoints.down('sm')]: {
    '& > button, & > a': {
      flex: 1
    }
  }
}))

// eslint-disable-next-line no-undef
const clickHandler = (
  event: Event,
  name: string,
  profileType: string,
  targetName: string,
  targetUuid: string,
  uuid: string
) => {
  window.dataLayer.push({
    event,
    cont_prf_name: name,
    cont_prf_type: profileType,
    cont_enquired_at_name: targetName,
    cont_enquired_at_uuid: targetUuid,
    cont_prf_uuid: uuid
  })
}

//todo: fix lint
// eslint-disable-next-line no-unused-vars
export const ContactDescription = ({ description, revealName, skipReveal, ...props }: ContactDescriptionProps) => (
  <RevealLink {...props} skipReveal={skipReveal} newWindow>
    {revealName}
  </RevealLink>
)

const StyledAvatar = styledComponent(Avatar)(({ theme }) => ({
  width: 92,
  height: 92,
  marginRight: '16px',
  borderRadius: '8px',
  border: `1px solid ${theme.palette.grey[600]}`
}))

export const SpecialistPracticeBookingCardLayout = ({
  specialistPractice,
  children
}: PropsWithChildren<SpecialistPracticeBookingCardProps>) => {
  const { practice } = useContext(ProfileContext)
  const translate = useTranslateMessage()
  const { language } = useContext(AppDataContext)

  const { contactName, logo, overallExperience, reviewsTotal, address } = specialistPractice
  const profileAvatarAbbreviation = contactName?.[0].toUpperCase()

  return (
    <Root container direction="column" alignItems="center">
      {(!!specialistPractice || !!practice) && (
        <Grid container item direction="row" wrap="nowrap">
          <Grid item>
            <StyledAvatar src={logo}>{profileAvatarAbbreviation}</StyledAvatar>
          </Grid>
          <Grid container item direction="column" rowSpacing={1}>
            <Grid item mb="4px">
              <Typography fontSize="16px" lineHeight="22.4px" variant="h4" color="text.primary">
                {contactName}
              </Typography>
            </Grid>
            {!!reviewsTotal && (
              <Grid container gap="4px" flexDirection="column">
                {address && (
                  <Grid item>
                    <Typography
                      variant="body2"
                      color="text.secondary"
                    >{`${address?.street1[language]}, ${address?.city[language]} ${address?.postcode[language]}`}</Typography>
                  </Grid>
                )}
                <Grid container alignItems="center" columnSpacing={1} sx={{ marginLeft: 0 }}>
                  <Grid container width="auto">
                    <Rating
                      size="small"
                      sx={{
                        fontSize: '15px'
                      }}
                      value={1}
                      max={1}
                      readOnly
                    />
                  </Grid>
                  <Grid item sx={{ paddingLeft: '4px !important' }}>
                    <Typography variant="body2" fontWeight="600">
                      {overallExperience}
                    </Typography>
                  </Grid>
                  <Grid item sx={{ paddingLeft: '8px !important' }}>
                    <Typography variant="body2" fontWeight="400" color="text.disabled">
                      {reviewsTotal} {translate('reviews.reviews').toLowerCase()}
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
            )}
          </Grid>
        </Grid>
      )}
      <RevealsContainer>{children}</RevealsContainer>
    </Root>
  )
}

export const SpecialistPracticeContactDetails = ({
  specialistPractice,
  isSpecialist,
  activeButton
}: SpecialistPracticeBookingCardProps) => {
  const { specialist } = useContext(ProfileContext)
  const translate = useTranslateMessage()
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'))

  const { profileName, contactName, phone, email, externalBookingLink, externalId, practiceExternalId, id, slug } =
    specialistPractice

  const onClickHandler = (event: Event) => () => {
    clickHandler(
      event,
      profileName,
      isSpecialist ? 'Specialist' : 'Practice',
      contactName,
      practiceExternalId,
      externalId
    )
  }
  return (
    <>
      {!!(phone?.length || email?.length || externalBookingLink?.length) && (
        <SpecialistPracticeBookingCardLayout isSpecialist={isSpecialist} specialistPractice={specialistPractice}>
          {!!phone && (
            <ContactDescription
              active={activeButton === 'call' || activeButton === 'all'}
              description={translate('phone_no')}
              href={`tel:${phone}`}
              specialistId={specialist?.id}
              practiceId={id}
              revealName={isMobile ? translate('call') : phone}
              skipReveal={isMobile}
              slug={slug}
              icon={<LocalPhoneIcon />}
              revealTitle={isMobile ? translate('call') : translate('bookAppointment.reveal.showPhoneNumber')}
              requestType={RequestTypes.PHONE}
              onClickRevealHandler={onClickHandler('prf_pg_cont_popup_reveal_tel')}
              onClickLinkHandler={
                isMobile ? onClickHandler('prf_pg_cont_popup_reveal_tel') : onClickHandler('prf_pg_cont_popup_tel_link')
              }
            />
          )}
          {!!email && (
            <ContactDescription
              active={activeButton === 'email' || activeButton === 'all'}
              description={translate('email')}
              href={`mailto:${email}?subject=${translate('email.subject')}`}
              specialistId={specialist?.id}
              practiceId={id}
              revealName={translate('email')}
              slug={slug}
              skipReveal
              revealLink={`mailto:${email}?subject=${translate('email.subject')}`}
              icon={<QuestionAnswerIcon />}
              revealTitle={translate('bookAppointment.reveal.email')}
              requestType={RequestTypes.EMAIL}
              onClickLinkHandler={onClickHandler('prf_pg_cont_popup_reveal_email')}
              onClickRevealHandler={onClickHandler('prf_pg_cont_popup_reveal_email')}
            />
          )}
          {!!externalBookingLink && (
            <ContactDescription
              active={activeButton === 'book' || activeButton === 'all'}
              description={isMobile ? translate('profile.booking') : translate('profile.booking_external_link')}
              revealName={translate('bookAppointment.reveal.book')}
              href={externalBookingLink}
              icon={<EventAvailableIcon />}
              requestType={RequestTypes.EXTERNAL_BOOKING_LINK}
              revealTitle={translate('bookAppointment.reveal.book')}
              skipReveal
              slug={slug}
              specialistId={specialist?.id}
              practiceId={id}
              primaryRevealStyle
              onClickLinkHandler={onClickHandler('prf_pg_book_popup_booking_link')}
            />
          )}
        </SpecialistPracticeBookingCardLayout>
      )}
    </>
  )
}
