import React, { useState, PropsWithChildren, ReactNode } from 'react'
import { styledComponent, Box, useTheme, useMediaQuery } from '@hermes/web-components'
import { RequestTypes } from '../constants/profile'
import { useSendAnalytic } from '../hooks/useSendAnalytic'

export const DetailsLink = styledComponent(Box, {
  shouldForwardProp: (prop) => prop !== 'primary' && prop !== 'secondary' && prop !== 'tertiary'
})<{ type: 'primary' | 'secondary' | 'tertiary'; href?: string; target?: string }>(({ theme, type }) => ({
  color: type === 'secondary' ? theme.palette.text.darkNavy : theme.palette.common.white,
  textDecoration: 'none',
  ...(type === 'primary'
    ? {
        ...(theme.components?.MuiButton?.styleOverrides?.root as object),
        ...(theme.components?.MuiButton?.styleOverrides?.containedPrimary as object)
      }
    : {}),
  ...(type === 'secondary'
    ? {
        backgroundColor: theme.palette.secondary.light
      }
    : {}),
  ...(type === 'tertiary'
    ? {
        ...(theme.components?.MuiButton?.styleOverrides?.outlinedPrimary as object)
      }
    : {}),
  cursor: 'pointer',
  padding: '8px 16px',
  borderRadius: '24px',
  border: `1px solid ${theme.palette.primary.main}`,
  display: 'flex',
  alignItems: 'center',
  flexWrap: 'nowrap',
  justifyContent: 'center',

  '&:active, &:focus': {
    outline: 'none'
  },
  p: {
    ...theme.typography[type === 'secondary' ? 'body2' : 'body1'],
    fontWeight: 500,
    margin: 0,
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis'
  },
  svg: {
    width: '16px',
    height: '16px',
    marginRight: '9px'
  }
}))

export const renderWithSpecialCharacters = (value = '', mimType = 'text/html') => {
  try {
    if (!value) {
      return ''
    }
    const parser = new DOMParser()
    return parser.parseFromString(value, mimType as DOMParserSupportedType).body.textContent || ''
  } catch (_err) {
    return value || ''
  }
}

export const RevealLink = ({
  children,
  href,
  revealTitle,
  skipReveal,
  newWindow,
  requestType,
  onClickRevealHandler,
  onClickLinkHandler,
  specialistId,
  practiceId,
  slug,
  icon,
  active,
  revealLink
}: PropsWithChildren<{
  href?: string
  revealTitle: string
  skipReveal?: boolean
  newWindow?: boolean
  requestType: RequestTypes
  onClickRevealHandler?: () => void
  onClickLinkHandler?: () => void
  specialistId?: number
  practiceId?: number
  slug?: string
  icon?: ReactNode
  primaryRevealStyle?: boolean
  active?: boolean
  revealLink?: string
}>) => {
  const [reveal, setReveal] = useState(false)
  const sendAnalytic = useSendAnalytic(slug, specialistId, practiceId)
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'))
  const activeButtonStyle = active ? 'primary' : 'tertiary'
  const skipRevealButtonStyle = skipReveal ? activeButtonStyle : 'secondary'

  const onClickReveal = () => {
    sendAnalytic(requestType)
    setReveal(true)
    if (onClickRevealHandler) {
      onClickRevealHandler()
    }
  }

  const onClickLink = () => {
    sendAnalytic(requestType)
    if (onClickLinkHandler) {
      onClickLinkHandler()
    }
  }

  if (!reveal && !skipReveal) {
    return (
      <DetailsLink
        /* eslint-disable-next-line no-nested-ternary */
        component={isMobile ? 'a' : revealLink ? 'a' : 'button'}
        type={active ? 'primary' : 'tertiary'}
        role="button"
        onClick={onClickReveal}
      >
        {icon}
        <p>{revealTitle}</p>
      </DetailsLink>
    )
  }

  return (
    <DetailsLink
      type={skipRevealButtonStyle}
      component="a"
      href={href ? renderWithSpecialCharacters(href) : undefined}
      target={newWindow && href ? '_blank' : undefined}
      onClick={onClickLink}
      role="link"
    >
      {icon}
      <p>{children}</p>
    </DetailsLink>
  )
}
